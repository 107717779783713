<template>
  <!-- <div class="animated fadeIn"> -->
  <div>
  </div>
</template>

<script>
import axios from 'axios'
import {api} from '@/components/apiurls'
import {authHeader} from '@/components/global'
import { mapGetters } from "vuex";

export default {
  name: 'Dashboard',
  components: {

  },
  data() {
    return {
      customerOptions:[],
      vesselOptions:[],
      vendorOptions:[],
      productOptions:[],
      departmentOptions:[],
      
    };
  },
  computed: {
    ...mapGetters([ "getCustomerList", "getVesselList", "getVendorList", "getProductList", "getDepartmentList" ])
  },
  async mounted()
  {
    // GET lookup options from store - customer / vessel / vendor / product / department
    // this.retrieve_lookup_options()
  },
  methods: {
    async retrieve_lookup_options()
    {
      let param = {term:''}
      this.loading = true
      // customer
      this.customerOptions = await this.getCustomerList
      if (this.customerOptions.length == 0) {
        await this.$store.dispatch("loadCustomerList", param)
        this.customerOptions = await this.getCustomerList
      }
      // vessel
      this.vesselOptions = await this.getVesselList
      if (this.vesselOptions.length == 0) {
        await this.$store.dispatch("loadVesselList", param)
        this.vesselOptions = await this.getVesselList
      }
      // department
      this.departmentOptions = await this.getDepartmentList
      if (this.departmentOptions.length == 0) {
      await this.$store.dispatch("loadDepartmentList", param)
      this.departmentOptions = await this.getDepartmentList
      }
      // product
      this.productOptions = await this.getProductList
      if (this.productOptions.length == 0) {
      await this.$store.dispatch("loadProductList", param)
      this.productOptions = await this.getProductList
      }
      // vendor
      this.vendorOptions = await this.getVendorList
      if (this.vendorOptions.length == 0) {
      await this.$store.dispatch("loadVendorList", param)
      this.vendorOptions = await this.getVendorList
      }
      this.loading = false
    },

  }
}
</script>